import {
  GameAlignment,
  GameOutcome,
  GamePeriod,
  GameStatus,
  HottestGame,
  ApiResponse,
  Scoreboard,
  ScoreboardGame,
  ScoreboardGameBasic,
  Sport,
  isGameClosed,
  isGameException,
  OddVariant,
  ReducedGameStatus,
  GameDetail,
} from "common";
import { formatRankSuffix } from "./numbers";
import { convertDateToStringWithoutTime, formatServerDate } from "./date";

export async function getGameDetail(sport: Sport, id: string, ip: any) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api/sports/${sport}/game-detail/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Client-IP": "::1",
      },
      cache: "no-store",
    }
  );
  if (!res.ok) {
    throw new Error("Failed to fetch game detail");
  }
  console.log("FETCHING GAME DETAIL:", res);

  return res.json();
}

export async function getGameDiscussion(id: string) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api/discussions/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-store",
    }
  );
  if (!res.ok) {
    throw new Error("Failed to fetch game discussion");
  }
  return res.json();
}

export function buildScoreboardRequest(
  sport?: Sport,
  date?: string,
  detailed?: boolean
): string {
  const baseUrl: string = `${
    process.env.NEXT_PUBLIC_API_URL
  }/client/scoreboard/${date || convertDateToStringWithoutTime(new Date())}`;
  const params: URLSearchParams = new URLSearchParams();
  if (sport && sport !== "all") {
    params.append("sport", sport);
  }
  if (detailed) {
    params.append("detailed", "true");
  }
  const request = `${baseUrl}?${params}`;
  console.log("Fetching data for request:", request);
  return request;
}
export async function getScoreboard(
  ip: any,
  sport?: Sport,
  date?: string,
  detailed?: boolean
): Promise<ApiResponse<Scoreboard>> {
  try {
    const request = buildScoreboardRequest(sport, date, detailed);
    console.log("Fetching data for request:", request);
    const res = await fetch(request, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Client-IP": ip,
      },
      cache: "no-store",
    });

    if (!res.ok) {
      console.error("Fetch failed:", res.status, res.statusText);
      throw new Error(`Error fetching scoreboard: ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
    console.error("Error in getScoreboard:", error);
    throw error;
  }
}
export async function getStartingGame(
  sport?: Sport
): Promise<ApiResponse<GameDetail>> {
  try {
    const request: string = `${process.env.NEXT_PUBLIC_API_URL}/api/sports/${sport}/starting-game`;
    const res = await fetch(request, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-store",
    });
    // if (!res.ok) {
    //   console.error("Fetch failed:", res.status, res.statusText);
    //   throw new Error(`Error fetching starting game: ${res.statusText}`);
    // }
    const data = await res.json();
    return data;
    // return await res.json();
  } catch (error) {
    console.error("Error in getStartingGame:", error);
    throw error;
  }
}
export function buildHotGamesRequest(
  sport?: Sport,
  odd?: OddVariant,
  limit?: number
): string {
  const baseUrl: string = `${process.env.NEXT_PUBLIC_API_URL}/api/picks/hot-games`;
  const params: URLSearchParams = new URLSearchParams();
  if (sport && sport !== "all") {
    params.append("sport", sport);
  }
  if (odd && odd !== "all") {
    params.append("odd", odd);
  }
  params.append("limit", `${limit || 1}`);
  return `${baseUrl}?${params}`;
}
export async function getHottestGame(
  sport?: Sport,
  limit?: number
): Promise<ApiResponse<HottestGame[]>> {
  const baseUrl = `${process.env.NEXT_PUBLIC_API_URL}/api/picks/hot-games`;
  const params = new URLSearchParams();
  if (sport) {
    params.append("sport", sport);
  }
  params.append("limit", `${limit ? limit : "1"}`);
  const request = `${baseUrl}?${params}`;

  try {
    const res = await fetch(request, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-store",
    });

    if (!res.ok) {
      return { status: "error", message: "Failed to fetch hottest game" };
    }

    return await res.json();
  } catch (error) {
    console.error("Fetch Error:", error);
    return { status: "error", message: "Network error" };
  }
}

export async function getGameSportsbooks(sport: Sport, ids: number[]) {
  const gameIDs = ids.join(",");
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/sports/${sport}/games/gameIDs=${gameIDs}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-store",
      }
    );
    if (!res.ok) {
      console.error(`Failed to fetch game sportsbooks for games: ${gameIDs}`);
    }
    return await res.json();
  } catch (error) {
    console.error(`Fetch error:`, error);
  }
}

export function sortGamesByStatus(games: ScoreboardGame[]): ScoreboardGame[] {
  const statusOrder: { [key: string]: number } = {
    InProgress: 1,
    Scheduled: 2,
    Final: 3,
    "F/OT": 4,
    "F/SO": 5,
    Postponed: 6,
  };
  games.sort((a, b) => {
    return statusOrder[a.status] - statusOrder[b.status];
  });
  return games;
}
export function defaultGroupedGames(): GroupedGames {
  return {
    Live: [],
    Pregame: [],
    Final: [],
  };
}
export type GameCounts = Record<Sport, number>;
export function defaultGameCounts(): GameCounts {
  return {
    all: 0,
    nfl: 0,
    nba: 0,
    mlb: 0,
    nhl: 0,
  };
}
// Yesterday starting at 0, Today 1, Tomorrow 2 and so on.
export type GamesByDate = ScoreboardGame[][];
export function calculateGameCounts(games: ScoreboardGame[]): GameCounts {
  let counts: GameCounts = defaultGameCounts();
  for (const game of games) {
    counts[game.league] += 1;
    counts.all += 1;
  }
  return counts;
}
export function calculateTotalGameCount(counts: GameCounts): number {
  let total: number = counts.nfl + counts.nba + counts.mlb + counts.nhl;
  return total;
}
export type ReducedSport = Exclude<Sport, "all">;
export type GamesBySport = {
  [key in ReducedSport]?: number[];
};
export function defaultGamesBySport(): GamesBySport {
  return {
    nfl: [],
    nba: [],
    mlb: [],
    nhl: [],
  };
}
export function groupGamesBySport(games: ScoreboardGame[]): GamesBySport {
  let grouped: GamesBySport = {};
  games.forEach((game, index) => {
    const sport = game.league as ReducedSport;
    if (!grouped[sport]) {
      grouped[sport] = [];
    }
    grouped[sport]?.push(index);
  });
  return grouped;
}
export function groupGamesBySport2(games: ScoreboardGame[]): GamesBySport {
  let grouped: GamesBySport = defaultGamesBySport();
  games.forEach((game, index) => {
    const sport = game.league as ReducedSport;
    grouped[sport]!.push(index);
  });
  return grouped;
}
export type GamesByStatus = {
  [key in ReducedGameStatus]: number[];
};
export function defaultGamesByStatus(): GamesByStatus {
  return {
    InProgress: [],
    Scheduled: [],
    Final: [],
  };
}
export function groupGamesByStatus2(games: ScoreboardGame[]): GamesByStatus {
  let grouped: GamesByStatus = {
    InProgress: [],
    Scheduled: [],
    Final: [],
  };
  games.forEach((game, index) => {
    let status = game.status as ReducedGameStatus;
    if (isGameClosed(status)) {
      status = "Final";
    }
    grouped[status].push(index);
  });

  return grouped;
}
export function groupGamesByStatus(games: ScoreboardGame[]): GroupedGames {
  const grouped = games.reduce<GroupedGames>((accumulator, game) => {
    const statusKey = formatScoresGameStatus(game.status);
    if (!accumulator[statusKey]) {
      accumulator[statusKey] = [];
    }
    accumulator[statusKey]!.push(game);
    return accumulator;
  }, defaultGroupedGames());

  Object.keys(grouped).forEach((key) => {
    if (grouped[key]!.length === 0) {
      delete grouped[key];
    }
  });

  return grouped;
}

export function groupGamesByLeague(games: ScoreboardGame[]): GroupedGames {
  const grouped = games.reduce<GroupedGames>((accumulator, game) => {
    const leagueKey = game.league;
    if (!accumulator[leagueKey]) {
      accumulator[leagueKey] = [];
    }
    accumulator[leagueKey]!.push(game);
    return accumulator;
  }, defaultGroupedGames());

  Object.keys(grouped).forEach((key) => {
    if (grouped[key as Sport]!.length === 0) {
      delete grouped[key as Sport];
    }
  });

  return grouped;
}
export function formatScoresGameStatus(status: GameStatus): string {
  switch (status) {
    case "Scheduled":
      return "Pregame";
    case "InProgress":
      return "Live";
    default:
      return "Final";
  }
}
export function formatScoreView(view: string): string {
  switch (view) {
    case "pregame":
      return "Pregame";
    case "live":
      return "Live";
    default:
      return "Final";
  }
}

export type GroupedGames = Partial<Record<string, ScoreboardGame[]>>;
export type GameView = "all" | "pregame" | "live" | "final";

export function filterGamesByStatus(
  data: ScoreboardGame[],
  view: GameView
): ScoreboardGame[] {
  switch (view) {
    case "live":
      return data.filter((game) => game.status === "InProgress");
    case "pregame":
      return data.filter((game) => game.status === "Scheduled");
    case "final":
      return data.filter((game) => isGameClosed(game.status));
    default:
      return data;
  }
}

export function formatBoxscorePeriod(
  sport: Sport,
  period: GamePeriod,
  season?: number
): string {
  switch (sport) {
    case "nfl":
    case "nba":
      if (period.value === 5) return "OT";
      if (period.value === 6) return "OT2";
      break;
    case "nhl":
      if (period.value === 4) return "OT";
      if (season && season === 3) {
        if (period.value && period.value > 4) {
          return `${period.value - 3}OT`;
        }
      }
      if (period.value === 5) return "SO";
      break;
  }
  return `${period.value}`;
}

export function formatGameClock(num?: number, leadingZero?: boolean): string {
  if (!num) {
    return `${leadingZero ? "00" : "0"}`;
  }
  if (num < 10) {
    return `${leadingZero ? "0" : ""}${num}`;
  }
  return `${num}`;
}

export function adjustHexBrightness(hex: string, pct: number) {
  hex = hex.replace(/^#/, "");
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  r = Math.min(255, Math.max(0, r + Math.round((r * pct) / 100)));
  g = Math.min(255, Math.max(0, g + Math.round((g * pct) / 100)));
  b = Math.min(255, Math.max(0, b + Math.round((b * pct) / 100)));

  let result: string = `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

  return result;
}
export function getLivePeriod(periods: GamePeriod[]): number {
  for (let i = 0; i < periods.length; i++) {
    const entry = periods[i];
    if (entry.value === undefined) {
      if (i === 0) {
        return 0;
      }
      return i - 1;
    }
  }
  return periods.length - 1;
}

export function getTotalScores(periods: GamePeriod[]): {
  away: number;
  home: number;
} {
  return periods.reduce(
    (acc, curr) => {
      acc.away += curr.awayScore || 0;
      acc.home += curr.homeScore || 0;
      return acc;
    },
    { away: 0, home: 0 }
  );
}

export interface LiveGame {
  period: GamePeriod;
  status: string;
  timeRemaining: string;
  hideIndicator?: boolean;
}

export function isGameLive(
  game: ScoreboardGameBasic,
  status?: GameStatus,
  season?: number
): LiveGame | undefined {
  if ((status && status === "InProgress") || game.status === "InProgress") {
    const livePeriodIdx: number = getLivePeriod(game.period);
    const livePeriod = game.period[livePeriodIdx];

    // init formatted status parts.
    let liveStatus: string = "";
    const statusSuffix: string = `${livePeriod.value || 1}${formatRankSuffix(
      livePeriod.value || 1
    )}`;

    // hide time if there is no time. exceptions also apply.
    let noTime: boolean = !livePeriod.minutes && !livePeriod.seconds;

    // hide the indicator when End or Half.
    let hideIndicator: boolean = false;

    switch (game.league) {
      case "mlb":
        noTime = true;
        liveStatus = `${livePeriod.status || "End"} ${statusSuffix}`;
        if (noTime && livePeriod.status === undefined) {
          hideIndicator = true;
        }
        break;
      case "nfl":
      case "nba":
        if (noTime) {
          hideIndicator = true;
          if (livePeriodIdx === 1) {
            liveStatus = "Half";
            break;
          }
        }
        switch (livePeriodIdx) {
          case 3:
            liveStatus = `${noTime ? "End" : ""} OT ${
              !noTime ? statusSuffix : ""
            }`;
            break;
          case 4:
            liveStatus = `${noTime ? "End" : ""} OT2 ${
              !noTime ? statusSuffix : ""
            }`;
            break;
        }
      case "nhl":
        if (noTime) {
          hideIndicator = true;
        }
        if (season && season === 3) {
          if (livePeriodIdx === 3) {
            liveStatus = `${noTime ? "End" : ""} OT ${
              !noTime ? statusSuffix : ""
            }`;
          }
          if (livePeriodIdx > 3) {
            liveStatus = `${noTime ? "End" : ""} ${livePeriodIdx - 2}OT ${
              !noTime ? statusSuffix : ""
            }`;
          }
        } else {
          switch (livePeriodIdx) {
            case 3:
              liveStatus = `${noTime ? "End" : ""} OT ${
                !noTime ? statusSuffix : ""
              }`;
              break;
            case 4:
              liveStatus = `${noTime ? "End" : ""} SO ${
                !noTime ? statusSuffix : ""
              }`;
              break;
          }
        }
      default:
        if (noTime) {
          hideIndicator = true;
        }
        liveStatus = `${noTime ? "End" : ""} ${statusSuffix}`;
        break;
    }
    // create a time remaining string if noTime is false.
    const timeRemaining: string | undefined = noTime
      ? undefined
      : `${formatGameClock(livePeriod.minutes, false)}:${formatGameClock(
          livePeriod.seconds,
          true
        )}`;
    return {
      period: livePeriod,
      status: `${liveStatus} ${timeRemaining || ""}`.trim(),
      timeRemaining: timeRemaining || "",
      hideIndicator: hideIndicator,
    };
  }
  return undefined;
}

export function formatGameStatus(game: ScoreboardGame): string {
  const finalPeriod = game.period.length;
  console.log("FINAL PERIOD:", game);
  switch (game.league) {
    case "mlb":
      return `${game.status}
      ${
        game.status === "Final" && finalPeriod && finalPeriod > 9
          ? `/${finalPeriod}`
          : ""
      }`;
    case "nhl":
      if (!game.series) return game.status;
      if (
        finalPeriod &&
        finalPeriod >= 5 &&
        (game.status === "F/OT" || game.status === "F/SO")
      ) {
        return `F/${finalPeriod - 3}OT`;
      } else {
        return `${game.status}`;
      }
    default:
      return game.status;
  }
}

export function getGameOutcome(
  homeScore: number,
  awayScore: number
): GameOutcome {
  if (homeScore > awayScore) {
    return "home";
  } else if (awayScore > homeScore) {
    return "away";
  }
  return "tie";
}

export function getGameWinner(game: ScoreboardGameBasic): GameAlignment | null {
  if (game.status === "Suspended") {
    return null;
  }
  if (!isGameException(game.status) && isGameClosed(game.status)) {
    const totalScores = getTotalScores(game.period);
    if (totalScores.away > totalScores.home) {
      return "away";
    } else if (totalScores.home > totalScores.away) {
      return "home";
    }
  }
  return null;
}

export function getFinalPeriod(periods: GamePeriod[]): number | undefined {
  return periods[periods.length - 1].value;
}

export function getInPossession(
  game: ScoreboardGame,
  alignment: GameAlignment
): boolean {
  if (game.league === "nfl") {
    if (
      alignment === "away" &&
      game.possession === game.awayTeam.abbreviation
    ) {
      return true;
    }
    if (
      alignment === "home" &&
      game.possession === game.homeTeam.abbreviation
    ) {
      return true;
    }
  }
  return false;
}
