"use client";
import cache from './globalCache';
import { useState, useEffect, useRef } from 'react';

interface WebSocketOptions {
  onOpen?: () => void;
  onClose?: () => void;
  onMessage?: (data: any) => void;
  onError?: (error: Event) => void;
  maxReconnectAttempts?: number;
}

const useWebSocket = (url: string, options: WebSocketOptions = {}) => {
  const [data, setData] = useState<any | null>(null);
  const socket = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const reconnectAttempts = useRef<number>(0);
  const shouldReconnect = useRef<boolean>(true);
  const maxReconnectAttempts = options.maxReconnectAttempts || 86400;

  const connectWebSocket = () => {
    if (socket.current && socket.current.readyState !== WebSocket.CLOSED && socket.current.readyState !== WebSocket.CLOSING) {
      return;
    }

    socket.current = new WebSocket(url);

    socket.current.onopen = () => {
      console.log('WebSocket Connected');
      setIsConnected(true);
      reconnectAttempts.current = 0;
      shouldReconnect.current = true;
      options.onOpen?.();
    };


    socket.current.onmessage = (event: MessageEvent) => {
      console.log("Received raw data:", event);
      try {
        const parsedData = JSON.parse(event.data);
        setData(parsedData);
        options.onMessage?.(parsedData);
      } catch (error) {
        console.error('Error parsing WebSocket message', error);
        options.onError?.(error as Event);
      }
    };

    socket.current.onerror = (error: Event) => {
      console.error('WebSocket Error', error);
      options.onError?.(error);
    };

    socket.current.onclose = (e: CloseEvent) => {
      // cache.clear();
      console.log('WebSocket Disconnected');
      setIsConnected(false);
      if (shouldReconnect.current && reconnectAttempts.current < maxReconnectAttempts) {
        setTimeout(() => {
          reconnectAttempts.current++;
          console.log(`Attempting to reconnect (${reconnectAttempts.current}/${maxReconnectAttempts})...`);
          connectWebSocket();
        }, 1000 * Math.min(reconnectAttempts.current, 30));
      }
      options.onClose?.();
    };
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      shouldReconnect.current = false;
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [url]);

  return [data, socket.current, isConnected];
};

export default useWebSocket;
