// "use client";
// import { ScoreboardGame } from "common";
// import React from "react";
// import { useWebSocketMessage } from "../useWebSocketMessage";

// type ScoreboardGameDiff = Partial<ScoreboardGame>[];

// function useScoreboardUpdate(
//   data: ScoreboardGame[] | undefined,
//   setData: React.Dispatch<React.SetStateAction<ScoreboardGame[]>>,
//   setStatusUpdated: React.Dispatch<React.SetStateAction<boolean>>
// ) {
//   const wsGames: ScoreboardGameDiff = useWebSocketMessage("scoreboard");
//   // console.log("scoreboard message:", wsGames);

//   React.useEffect(() => {
//     if (typeof window === "undefined" || typeof document === undefined) return;
//     if (!wsGames?.length || !data) return;
//     let hasNewData = false;
//     let statusUpdated = false;
//     const updatedData: ScoreboardGame[] = data.map(
//       (game: ScoreboardGame) => {
//         const update = wsGames.find((wsGame) => wsGame.id === game.id);
//         if (update) {
//           if (update.status) {
//             statusUpdated = true;
//           }
//           hasNewData = true;
//           return {
//             ...game,
//             odds: update.odds ? { ...game.odds, ...update.odds } : game.odds,
//             awayTeam: update.awayTeam
//               ? { ...game.awayTeam, ...update.awayTeam }
//               : game.awayTeam,
//             homeTeam: update.homeTeam
//               ? { ...game.homeTeam, ...update.homeTeam }
//               : game.homeTeam,
//             awayRecord: update.awayRecord
//               ? { ...game.awayRecord, ...update.awayRecord }
//               : game.awayRecord,
//             homeRecord: update.homeRecord
//               ? { ...game.homeRecord, ...update.homeRecord }
//               : game.homeRecord,
//             awayLast10: update.awayLast10
//               ? { ...game.awayLast10, ...update.awayLast10 }
//               : game.awayLast10,
//             homeLast10: update.homeLast10
//               ? { ...game.homeLast10, ...update.homeLast10 }
//               : game.homeLast10,
//             awayStreak: update.awayStreak
//               ? { ...game.awayStreak, ...update.awayStreak }
//               : game.awayStreak,
//             homeStreak: update.homeStreak
//               ? { ...game.homeStreak, ...update.homeStreak }
//               : game.homeStreak,
//             awayInjuries: update.awayInjuries
//               ? { ...game.awayInjuries, ...update.awayInjuries }
//               : game.awayInjuries,
//             homeInjuries: update.homeInjuries
//               ? { ...game.homeInjuries, ...update.homeInjuries }
//               : game.homeInjuries,
//             awayStartingPlayer: update.awayStartingPlayer
//               ? { ...game.awayStartingPlayer, ...update.awayStartingPlayer }
//               : game.awayStartingPlayer,
//             homeStartingPlayer: update.homeStartingPlayer
//               ? { ...game.homeStartingPlayer, ...update.homeStartingPlayer }
//               : game.homeStartingPlayer,
//               startingLineup: {
//                 away: update.startingLineup?.away ? [...game.startingLineup?.away || [], ...update.startingLineup.away] : game.startingLineup?.away || [],
//                 home: update.startingLineup?.home ? [...game.startingLineup?.home || [], ...update.startingLineup.home] : game.startingLineup?.home || [],
//               },
//             status: update.status !== undefined ? update.status : game.status,
//             period: update.period !== undefined ? update.period : game.period,
//             totalPicks: update.totalPicks ? update.totalPicks : game.totalPicks,
//             totalComments: update.totalComments ? update.totalComments : game.totalComments,
//             discussion: update.discussion ? {
//               ...game.discussion,
//               ...update.discussion,
//               totalComments: update.discussion.totalComments || game.discussion.totalComments,
//               totalBettors: update.discussion.totalBettors || game.discussion.totalBettors,
//               comments: update.discussion.comments || game.discussion.comments,
//               avatars: update.discussion.avatars || game.discussion.avatars,
//           } : game.discussion
//           };
//         }
//         return game;
//       }
//     );

//     if (hasNewData) {
//       setData(updatedData);
//     }
//     if (statusUpdated) {
//       setStatusUpdated(true);
//     }
//   }, [wsGames]);
// }

// export default useScoreboardUpdate;

"use client";

import React from "react";
import { ScoreboardGame } from "common";
import { useWebSocketMessage } from "../useWebSocketMessage";

type ScoreboardGameDiff = Partial<ScoreboardGame>[];
type ScoreboardInput = ScoreboardGame | ScoreboardGame[];

function useScoreboardUpdate(
  data: ScoreboardInput,
  setData: React.Dispatch<React.SetStateAction<ScoreboardInput>>,
  setStatusUpdated: React.Dispatch<React.SetStateAction<boolean>>
) {
  const wsGames: ScoreboardGameDiff = useWebSocketMessage("scoreboard");

  React.useEffect(() => {
    if (typeof window === "undefined" || typeof document === undefined) return;
    if (!Array.isArray(wsGames) || !data) return;

    let hasNewData = false;
    let statusUpdated = false;

    const isSingleGame = !Array.isArray(data);
    const dataArray = isSingleGame ? [data] : data;

    const updatedData: ScoreboardGame[] = dataArray.map(
      (game: ScoreboardGame) => {
        const update = wsGames.find((wsGame) => wsGame.id === game.id);
        if (update) {
          if (update.status) {
            statusUpdated = true;
          }
          hasNewData = true;
          return updateGameData(game, update);
        }
        return game;
      }
    );

    if (hasNewData) {
      setData(isSingleGame ? updatedData[0] : updatedData);
    }
    if (statusUpdated) {
      setStatusUpdated(true);
    }
  }, [wsGames]);
}

function updateGameData(
  game: ScoreboardGame,
  update: Partial<ScoreboardGame>
): ScoreboardGame {
  return {
    ...game,
    ...update,
    odds: update.odds ? { ...game.odds, ...update.odds } : game.odds,
    awayTeam: update.awayTeam
      ? { ...game.awayTeam, ...update.awayTeam }
      : game.awayTeam,
    homeTeam: update.homeTeam
      ? { ...game.homeTeam, ...update.homeTeam }
      : game.homeTeam,
    awayRecord: update.awayRecord
      ? { ...game.awayRecord, ...update.awayRecord }
      : game.awayRecord,
    homeRecord: update.homeRecord
      ? { ...game.homeRecord, ...update.homeRecord }
      : game.homeRecord,
    awayLast10: update.awayLast10
      ? { ...game.awayLast10, ...update.awayLast10 }
      : game.awayLast10,
    homeLast10: update.homeLast10
      ? { ...game.homeLast10, ...update.homeLast10 }
      : game.homeLast10,
    awayStreak: update.awayStreak
      ? { ...game.awayStreak, ...update.awayStreak }
      : game.awayStreak,
    homeStreak: update.homeStreak
      ? { ...game.homeStreak, ...update.homeStreak }
      : game.homeStreak,
    awayInjuries: update.awayInjuries
      ? { ...game.awayInjuries, ...update.awayInjuries }
      : game.awayInjuries,
    homeInjuries: update.homeInjuries
      ? { ...game.homeInjuries, ...update.homeInjuries }
      : game.homeInjuries,
    awayStartingPlayer: update.awayStartingPlayer
      ? { ...game.awayStartingPlayer, ...update.awayStartingPlayer }
      : game.awayStartingPlayer,
    homeStartingPlayer: update.homeStartingPlayer
      ? { ...game.homeStartingPlayer, ...update.homeStartingPlayer }
      : game.homeStartingPlayer,
    startingLineup: {
      away: update.startingLineup?.away
        ? [...(game.startingLineup?.away || []), ...update.startingLineup.away]
        : game.startingLineup?.away || [],
      home: update.startingLineup?.home
        ? [...(game.startingLineup?.home || []), ...update.startingLineup.home]
        : game.startingLineup?.home || [],
    },
    status: update.status !== undefined ? update.status : game.status,
    period: update.period !== undefined ? update.period : game.period,
    totalPicks: update.totalPicks ? update.totalPicks : game.totalPicks,
    totalComments: update.totalComments
      ? update.totalComments
      : game.totalComments,
    discussion: update.discussion
      ? {
          ...game.discussion,
          ...update.discussion,
          totalComments:
            update.discussion.totalComments || game.discussion.totalComments,
          totalBettors:
            update.discussion.totalBettors || game.discussion.totalBettors,
          comments: update.discussion.comments || game.discussion.comments,
          avatars: update.discussion.avatars || game.discussion.avatars,
        }
      : game.discussion,
  };
}

export default useScoreboardUpdate;
