"use client";
import React, { createContext, useContext } from 'react';
import useWebSocket from './useWebSocket';

const WebSocketContext = createContext<[any | null, WebSocket | null]>([null, null]);

export const useWebSocketContext = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }: {children: React.ReactNode}) => {
    const [data, socket] = useWebSocket(`${process.env.NEXT_PUBLIC_WEBSOCKET_URL}`);

    return (
        <WebSocketContext.Provider value={[data, socket]}>
            {children}
        </WebSocketContext.Provider>
    );
};
