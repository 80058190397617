"use client";
import React from 'react';
import { useWebSocketContext } from './websocket-provider';
import { WebsocketMessage } from 'common';


export function useWebSocketMessage(messageType: string): any {
    const [data, setData] = React.useState<any | null>(null);
    const [_, socket] = useWebSocketContext();
    React.useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            try {
                const message: WebsocketMessage = JSON.parse(event.data);
                if (message.type === messageType) {
                    console.log("incoming message:", message);
                    console.log("incoming message data:", message.data);
                    setData(message.data);
                }
            } catch (error) {
                console.error('error parsing websocket message:', error);
            }
        };
        if (socket) {
            socket.addEventListener('message', handleMessage);
        }
        return () => {
            if (socket) {
                socket.removeEventListener('message', handleMessage);
            }
        };
    }, [socket, messageType]);
    return data;
};
